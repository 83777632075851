import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogData } from '../model/data.model';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { InsurerPlanDetailsComponent } from '../modal-component/insurer-plan-details/insurer-plan-details.component';
import { OtherPlanDetailsComponent } from '../modal-component/other-plan-details/other-plan-details.component';
import { EmailComponent } from '../modal-component/email/email.component';
import { PlanModalComponent } from '../modal-component/plan-modal/plan-modal.component';
import { DisclaimerComponent } from '../modal-component/disclaimer/disclaimer.component';


@Injectable({
  providedIn: 'root'
})
export class CommonModalService {

    dialogConfig = new MatDialogConfig();

    constructor(
        public dialog: MatDialog,
        private overlay: Overlay
    ) {
        this.dialogConfig.height = '50%';
        this.dialogConfig.width = '50%';
        // Over-riding default data value for modal window - if required
        this.dialogConfig.maxWidth = '90vw';
        this.dialogConfig.maxHeight = '100vh';
        this.dialogConfig.position = {
            top: '5%',
        };
        this.dialogConfig.minWidth = '50%';

    }

    openDialog(obj: DialogData): Observable<any> {

        let childComponent: any = null;
        const { width, height, minWidth, classNameObtained, dataInfo } = obj;
        const scrollStrategy = this.overlay.scrollStrategies.reposition();
        this.dialogConfig.data = dataInfo;

        /**
         * These need to be changed  as number should be configurable.
         * Curently it is not .
         * TODOS- On urgent basis need to change
         */
        if (width) {
            this.dialogConfig.width = width;
        }
        if (height) {
            this.dialogConfig.height = height;
        }
        if (minWidth) {
            this.dialogConfig.minWidth = minWidth;
        }
        if (classNameObtained) {
            this.dialogConfig.panelClass = classNameObtained;
        }

        if (obj.type === 1) {
            childComponent = InsurerPlanDetailsComponent
        }

        if (obj.type === 2) {
            childComponent = OtherPlanDetailsComponent
        }

        if (obj.type === 3) {
            childComponent = EmailComponent
        }

        if (obj.type === 4) {
            childComponent = PlanModalComponent
        }

        if (obj.type === 5) {
            childComponent = DisclaimerComponent
        }

        if (childComponent === null) {
            return null as any;
        }

        const dialogRef = this.dialog.open(childComponent, {
            data: this.dialogConfig.data,
            position: this.dialogConfig.position,
            width: this.dialogConfig.width,
            height: this.dialogConfig.height,
            minWidth: this.dialogConfig.minWidth,
            panelClass: this.dialogConfig.panelClass,
            scrollStrategy,
        });

        return dialogRef.afterClosed();
    }
}
